<template>
  <v-card-text class="d-flex justify-center" style="height: 75%; width: 100%">
    <v-icon
      v-if="widget.payload == '1'"
      style="font-size: 70px"
      class="d-flex justify-center"
      :color="Color"
      v-on:click="
        widget.payload  = '0';
        switchpush(widget.payload );
      "
      >mdi-toggle-switch-variant</v-icon
    >
    <v-icon
      v-else
      style="font-size: 70px"
      class="d-flex justify-center"
      v-on:click="
        widget.payload  = '1' ;
        switchpush(widget.payload);
      "
      >mdi-toggle-switch-variant-off</v-icon
    >
  </v-card-text>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["widget"],
  methods: {
    ...mapActions(["doPublish"]),
    switchpush(statesend) 
    {
      this.doPublish({
        topic: this.widget.topic,
        payload: statesend == '1' ? "1" : "0",
      });
    },
    update() {
      try {
        this.stateofswitch = this.widget.payload;
        this.Color = this.widget.params.Color;
      } catch (err) {
        console.log("Lamp", "error", err);
      }
    },
  },
  data() {
    return {
      stateofswitch: this.widget.payload,
      Color: "red",
    };
  },

  mounted() {
    this.Color = this.widget.params.Color;
    this.update();
    this.$store.watch(
      (state) => {
        return state.widgets.filter((d) => d.topic == this.widget.topic);
      },
      () => {
        this.update();
      },
      { deep: true }
    );
  },
};
</script>

<style></style>
