<template>
  <v-container fill-height>
    <v-row justify="center" align="center">
      <v-card width="600" style="padding: 10px">
        <error-handler
          :errorMsg="errMsg"
          :msgtype="msgtype"
          v-if="errMsg != ''"
        />
        <v-toolbar color="#803D9A" dark>
          <v-toolbar-title>Reset Password</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <form @submit.prevent="reset()">
            <v-text-field
              v-model="password"
              placeholder="password"
              name="password"
              label="Password"
              type="password"
              required
            >
            </v-text-field>
            <v-text-field
              v-model="password_confirm"
              placeholder="password_confirm"
              name="password_confirm"
              label="Password confirm"
              type="password"
              required
            >
            </v-text-field>
          </form>
          <v-btn class="mr-4" type="submit">Submit</v-btn>
              <v-btn @click="clear">Clear</v-btn>
              <v-spacer></v-spacer>
              <v-btn text @click="$router.push('/login')">Login</v-btn>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import ErrorHandler from "../components/ErrorHandler.vue";
export default {
  components: { ErrorHandler },
  data() {
    return {
      password: "",
      password_confirm: "",
      errMsg: "",
      msgtype: "",
    };
  },
  methods: {
    clear() {
      this.password = "";
      this.password_confirm = "";
      this.errMsg = "";
    },
    async reset() {
      if (this.password != this.password_confirm) {
        this.errMsg = "Password confirmation mismatch!";
        this.msgtype = "error";
        return;
      }
      this.errMsg = "";
      await axios
        .get(
          this.$store.state.serverUrl +
            "resetpassword/" +
            this.$route.params.token +
            "/" +
            this.password
        )
        .then((response) => {
          return response;
        })
        .then((data) => {
          console.log(data);

          if (data.data.err) {
            this.msgtype = "error";
            this.errMsg = data.data.err;
          }
          if (data.data.msg) {
            this.msgtype = "success";
            this.errMsg = data.data.msg;
          }
        });
    },
  },
  mounted() {
    console.log(this.$route.params.token);
    if (this.$route.params == null) {
      this.$store.state.currentPath = "login";
      this.$router.push("/login");
    }
  },
};
</script>

<style></style>
