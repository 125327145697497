<template>
  <v-card-text>
    <v-row align="center" justify="space-around">
      <v-col align="center" class="text-h5">
        {{ Display(this.value) }}
      </v-col>
    </v-row>
    <v-row align="center" justify="space-around">
      <v-btn
        class="ma-1"
        color="green"
        style="font-size: 26px"
        v-on:click.native="
          up();
          datasend(value);
        "
      >
        +</v-btn
      >
      <v-btn
        class="ma-1"
        color="red"
        style="font-size: 26px"
        v-on:click.native="
          down();
          datasend(value);
        "
      >
        -</v-btn
      >
    </v-row>
  </v-card-text>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["widget"],
  data() {
    return {
      value: 0,
      min: 0,
      max: 100,
      step: 1,
      decimal: 0,
    };
  },
  methods: {
    ...mapActions(["doPublish"]),
    up() {
      this.value = Number(this.value) + Number(this.widget.params.step);
      if (this.value > Number(this.widget.params.max)) {
        this.value = Number(this.widget.params.max);
      }
    },
    down() {
      this.value = Number(this.value) - Number(this.widget.params.step);
      if (this.value < Number(this.widget.params.min)) {
        this.value = Number(this.widget.params.min);
      }
    },
    datasend(value) {
      this.doPublish({ topic: this.widget.topic, payload: String(value) });
    },
    update() {
      try {
        this.value = Number(this.widget.payload);
        this.min = Number(this.widget.params.min);
        this.max = Number(this.widget.params.max);
        this.step = Number(this.widget.params.step);
      } catch (err) {
        console.log("Silder", "error", err);
      }
    },
    Display(value) {
      return parseFloat(value).toFixed(this.widget.params.decimal);
    },
  },
  mounted() {
    this.value = 0;
    this.min = this.widget.params.min;
    this.max = this.widget.params.max;
    this.step = this.widget.params.step;
    this.decimal = this.widget.params.decimal;
    this.update();
    this.$store.watch(
      (state) => {
        return state.widgets.filter((d) => d.topic == this.widget.topic);
      },
      () => {
        this.update();
      },
      { deep: true }
    );
  },
};
</script>

<style></style>
