<template>
  <v-card-text style="font-size: 30px; text-align: center; color: black;">
      {{ this.widget.payload }}
  </v-card-text>
</template>


<script>
import { mapActions } from "vuex";
export default {
  props: ["widget"],
  methods: {
    ...mapActions(["doPublish"]),
    update() 
    {
        
    }
  },
  mounted() 
  {
    this.update();
    this.$store.watch(
      (state) => {
        return state.widgets.filter((d) => d.topic == this.widget.topic);
      },
      () => {
        this.update();
      },
      { deep: true }
    );
  },
};
</script>

<style></style>
