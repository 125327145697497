<template>
  <div>
    <v-container fluid>
      <v-row dense>
        <v-col v-for="w in this.$store.state.pages[0].BCDBWidgets" :key="w.id" :cols="w.cols" sm="1.5">
          <WidgetController :widget="w" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapActions } from "vuex";
import WidgetController from "../components/WidgetController1.vue";

export default {
  data() {
    return {
      
    };
  },
  components: {
    WidgetController,
  },
  computed: {
    ...mapState(["widgettype", "widgets", "pageId"]),
    ...mapActions(["doPublish", "doConnection"]),
  },
};
</script>

<style></style>
