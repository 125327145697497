<template>
  <div>
    <h2>Account Information</h2>
    <ul>
      <li v-for="account in accounts" :key="account.id">
        <strong>Name:</strong> {{ account.name }}<br />
        <strong>Last Name:</strong> {{ account.lastname }}<br />
        <strong>Email:</strong> {{ account.email }}<br />
        <strong>Token ID:</strong> {{ account.tokenId }}
      </li>
    </ul>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      accounts: [],
    };
  },
  mounted() {
    // Fetch the account data from the server
    this.fetchAccounts();
  },
  methods: {
    fetchAccounts() {
      // Send a request to the server to retrieve account data
      // For simplicity, let's assume you're using Axios library
      axios
        .get("/api/accounts")
        .then((response) => {
          // Assign the retrieved account data to the component's data
          this.accounts = response.data;
        })
        .catch((error) => {
          // Handle any errors that occurred during the request
          console.error(error);
        });
    },
  },
};
</script>
