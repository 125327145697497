<template>
  <v-card-text>
    <v-row align="center" justify="space-around">
      <v-col class="text-h5">
        <v-slider
          v-model="widget.payload"
          class="align-center"
          :max="max"
          :min="min"
          hide-details
          track-color="grey"
          always-dirty
          @change="datasend(widget.payload)"
        >
          <template v-slot:prepend>
            <v-icon @click="decrement"> mdi-minus </v-icon>
          </template>

          <template v-slot:append>
            <v-icon @click="increment"> mdi-plus </v-icon>
          </template>
        </v-slider>
      </v-col>
    </v-row>
    <v-row align="center" justify="space-around">
      <v-col align="center" class="text-h5">
        {{ this.widget.payload }}
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["widget"],
  data() {
    return {
      min: 0,
      max: 100,
      slider: 0,
    };
  },
  methods: {
    ...mapActions(["doPublish"]),
    datasend(datain) {
      this.doPublish({ topic: this.widget.topic, payload: datain.toString() });
    },
    update() {
      try {
        this.min = Number(this.widget.params.min);
        this.max = Number(this.widget.params.max);
      } catch (err) {
        //console.log("Silder", "error", err);
      }
    },
    decrement() {
      this.widget.payload--;
      this.datasend(this.widget.payload);
    },
    increment() {
      this.widget.payload++;
      this.datasend(this.widget.payload);
    },
  },
  mounted() {
    if (this.widget.params == null) {
      this.widget.params = {
        min: this.min,
        max: this.max,
      };
    } else {
      this.min = this.widget.params.min;
      this.max = this.widget.params.max;
    }
    this.update();
    this.$store.watch(
      (state) => {
        return state.widgets.filter((d) => d.topic == this.widget.topic);
      },
      () => {
        this.update();
      },
      { deep: true }
    );
  },
};
</script>

<style></style>
