<template>
  <div
    style="overflow: hidden"
    :style="{
      width: windowWidth + 'px',
      height: windowHeight - 100 + 'px',
    }"
  >
    <v-app-bar
      :collapse="!collapseState"
      height="40"
      :width="widgettype.length * collapseState * 40 + 60"
      v-show="this.$store.state.edit"
    >
      <v-icon
        @click="collapseState = !collapseState"
        v-show="this.$store.state.edit"
        >mdi-widgets</v-icon
      >
      <v-spacer></v-spacer>
      <div v-for="(w, i) in widgettype" :key="i" v-show="collapseState">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              @click="addWidget(w.name)"
              style="margin-left: 10px"
            >
              {{ w.icon }}
            </v-icon>
          </template>
          <span>{{ w.name }}</span>
        </v-tooltip>
      </div>
    </v-app-bar>

    <div v-for="(w, i) in widgets" :key="i">
      <WidgetController :widget="w"/>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import WidgetController from "../components/WidgetController.vue";

export default {
  components: {
    WidgetController,
  },
  computed: {
    ...mapState(["widgettype", "widgets", "pageId"]),
  },
  data() {
    return { collapseState: false };
  },
  methods: {
    addWidget(wname) {
      console.log(this.widgets);
      const newWidgetId =
        this.widgets.length == 0
          ? 0
          : this.widgets[this.widgets.length - 1].id + 1;
      if (wname == "ToggleSwitch") {
        this.widgets.push({
          id: newWidgetId,
          name: wname,
          pageId: this.pageId,
          payload: "",
          show: true,
          size: { w: 180, h: 160, x: 40, y: 40, minw: 160, minh: 140 },
          timestamp: null,
          topic: this.$store.state.user.token + "/",
          type: wname,
          deviceType: this.widgettype.find((f) => f.name == wname).deviceType,
          params: {
            Color: "green",
          },
        });
      } else if (wname == "Lamp") {
        this.widgets.push({
          id: newWidgetId,
          name: wname,
          pageId: this.pageId,
          payload: "",
          show: true,
          size: { w: 180, h: 160, x: 40, y: 40, minw: 160, minh: 140 },
          timestamp: null,
          topic: this.$store.state.user.token + "/",
          type: wname,
          deviceType: this.widgettype.find((f) => f.name == wname).deviceType,
          params: {
            Load: "Lamp",
            Color: "green",
          },
        });
      } else if (wname == "Step") {
        this.widgets.push({
          id: newWidgetId,
          name: wname,
          pageId: this.pageId,
          payload: "",
          show: true,
          size: { w: 180, h: 160, x: 40, y: 40, minw: 160, minh: 140 },
          timestamp: null,
          topic: this.$store.state.user.token + "/",
          type: wname,
          deviceType: this.widgettype.find((f) => f.name == wname).deviceType,
          params: {
            value: 0,
            min: 0,
            max: 100,
            step: 1,
            decimal: 0,
          },
        });
      } else if (wname == "Gauge") {
        this.widgets.push({
          id: newWidgetId,
          name: wname,
          pageId: this.pageId,
          payload: "",
          show: true,
          size: { w: 160, h: 160, x: 40, y: 40, minw: 140, minh: 140 },
          timestamp: null,
          topic: this.$store.state.user.token + "/",
          type: wname,
          deviceType: this.widgettype.find((f) => f.name == wname).deviceType,
          params: {
            unit: "",
            min: 0,
            max: 100,
            hollow: 70,
            startAngle: -135,
            endAngle: 135,
            Color: "green",
          },
        });
      } else if (wname == "Timer") {
        this.widgets.push({
          id: newWidgetId,
          name: wname,
          pageId: this.pageId,
          payload: "",
          show: true,
          size: { w: 260, h: 160, x: 100, y: 100, minw: 260, minh: 140 },
          timestamp: null,
          topic: this.$store.state.user.token + "/",
          type: wname,
          deviceType: this.widgettype.find((f) => f.name == wname).deviceType,
          backup: {
            H: 0,
            M: 0,
            S: 0,
          },
        });
      } else if (wname == "TextInput") {
        this.widgets.push({
          id: newWidgetId,
          name: wname,
          pageId: this.pageId,
          payload: "",
          show: true,
          size: { w: 200, h: 200, x: 40, y: 40, minw: 200, minh: 200 },
          timestamp: null,
          topic: this.$store.state.user.token + "/",
          type: wname,
          deviceType: this.widgettype.find((f) => f.name == wname).deviceType,
        });
      } else if (wname == "Chart") {
        this.widgets.push({
          id: newWidgetId,
          name: wname,
          pageId: this.pageId,
          payload: "",
          show: true,
          size: { w: 360, h: 320, x: 40, y: 40, minw: 140, minh: 140 },
          timestamp: null,
          topic: this.$store.state.user.token + "/",
          type: wname,
          deviceType: this.widgettype.find((f) => f.name == wname).deviceType,
          params: {
            name: "data",
            Color: "green",
          },
        });
      } else {
        this.widgets.push({
          id: newWidgetId,
          name: wname,
          pageId: this.pageId,
          payload: "",
          show: true,
          size: { w: 180, h: 160, x: 40, y: 40, minw: 160, minh: 140 },
          timestamp: null,
          topic: this.$store.state.user.token + "/",
          type: wname,
          deviceType: this.widgettype.find((f) => f.name == wname).deviceType,
        });
      }
    },
  },
  mounted() {},
};
</script>

<style></style>
