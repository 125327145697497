<template>
  <div>
    <div style="padding: 10px">
      <area-chart
        ref="chart"
        id="chart"
        type="area"
        :height="widget.size.h - 120"
        :width="widget.size.w - 20"
        :options="chartOptions"
        :series="series"
      />
    </div>
    <v-btn
      style="position: absolute; bottom: 20px; left: 20px; font-size: 16px"
      @click="resetData"
    >
      NOW
    </v-btn>
    <v-btn
      style="position: absolute; bottom: 20px; left: 100px; font-size: 16px"
      @click="loadLog(120, 1)"
    >
      1H
    </v-btn>
    <v-btn
      style="position: absolute; bottom: 20px; left: 170px; font-size: 16px"
      @click="loadLog(2880, 2)"
    >
      1D
    </v-btn>
    <v-btn
      style="position: absolute; bottom: 20px; left: 240px; font-size: 16px"
      @click="loadLog(20160, 3)"
    >
      7D
    </v-btn>
    <v-btn
      style="position: absolute; bottom: 20px; left: 320px; font-size: 16px"
      @click="exportToCSV"
    >
      Export CSV
    </v-btn>
  </div>
</template>

<script>
import AreaChart from "vue-apexcharts";
import axios from "axios";
import { nextTick } from 'vue';

export default {
  props: ["widget"],
  components: {
    AreaChart,
  },
  data() {
    return {
      series: [{ name: this.widget.params.name, data: [] }],
      chartOptions: {
        chart: {
          height: 350,
          type: "area",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          type: "datetime",
          categories: [],
          labels: {
            datetimeUTC: true,
          },
        },
        tooltips: {
          x: {
            format: "dd/MM/yy HH:mm",
          },
        },
        colors: [this.widget.params.Color],
      },
      point: 10,
      mode: 0,
    };
  },
  methods: {
    resetData() {
      this.series[0].data = [];
      this.chartOptions.xaxis.categories = [];
      this.point = 20;
      this.mode = 0;
      this.update();
    },
    async update() {
      this.series[0].data.unshift(this.widget.payload);
      this.series[0].data = this.series[0].data.slice(0, this.point); // Slice is more efficient than pop

      this.chartOptions.xaxis.categories.unshift(
        new Date(this.widget.timestamp).getTime() + 25200000
      );
      this.chartOptions.xaxis.categories =
        this.chartOptions.xaxis.categories.slice(0, this.point);

      this.chartOptions.chart.height = this.widget.size.h - 120;

      this.updateChart();
    },
    async loadLog(point, mode) {
      this.point = point;
      this.mode = mode;

      try {
        const response = await axios.get(
          this.$store.state.serverUrl + "getAll/" + this.widget.topic
        );
        const data = response.data;

        if (this.mode == 1 || this.mode == 2) {
          const slicedData = data.slice(0, this.point);
          this.series[0].data = slicedData.map((item) => item.p);
          this.chartOptions.xaxis.categories = slicedData.map(
            (item) => new Date(item.t).getTime() + 25200000
          );
        } else if (this.mode == 3) {
          const loopLimit = Math.min(data.length, this.point / 60);
          this.series[0].data = Array.from(
            { length: loopLimit },
            (_, i) => data[i * 60].p
          );
          this.chartOptions.xaxis.categories = Array.from(
            { length: loopLimit },
            (_, i) => new Date(data[i * 60].t).getTime() + 25200000
          );
        }

        this.updateChart();
      } catch (err) {
        console.error("Error loading log data", err);
      }
    },
    updateChart() {
      nextTick(() => {
        if (this.$refs.chart) {
          this.$refs.chart.updateOptions(this.chartOptions);
          this.$refs.chart.updateSeries(this.series);
        } else {
          //console.error("Chart ref is not defined.");
        }
      });
    },
    async  exportToCSV() {
      await this.loadLog(20160, 3);
      const csvData = [];
      const headers = ['Timestamp', 'Value'];
      
      // Add headers
      csvData.push(headers.join(','));
      
      // Map data and categories to CSV format
      for (let i = 0; i < this.series[0].data.length; i++) {
        const row = [
          new Date(this.chartOptions.xaxis.categories[i]).toISOString(), // Timestamp
          this.series[0].data[i] // Data value
        ];
        csvData.push(row.join(','));
      }
      
      // Convert array to CSV string
      const csvString = csvData.join('\n');
      
      // Create a Blob for the CSV data
      const blob = new Blob([csvString], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      
      // Create a link element and trigger a download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', '7-day-data.csv');
      document.body.appendChild(link);
      link.click();
      
      // Cleanup
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }
  },
  mounted() {
    this.$store.watch(
      (state) =>
        state.devices.find((device) => device.topic === this.widget.topic),
      (device) => {
        this.widget.payload = device.payload;
        this.series[0].name = this.widget.params.name;
        this.chartOptions.colors[0] = this.widget.params.Color;

        if (this.mode === 0) {
          this.update();
        }
      },
      { deep: true }
    );
  },
};
</script>

<style></style>
