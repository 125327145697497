<template>
  <v-container fluid @change="datasend">
    <v-row align="center">
      <v-col>
        <v-select
          v-model="select_Hour"
          :items="Hour"
          item-text="Hour"
          item-value="Hour"
          label="Hour"
          persistent-hint
          return-object
          single-line
          @change="datasend"
        ></v-select>
      </v-col>
      :
      <v-col>
        <v-select
          v-model="select_Minute"
          :items="Minute"
          item-text="Minute"
          item-value="Minute"
          label="Minute"
          persistent-hint
          return-object
          single-line
          @change="datasend"
        ></v-select>
      </v-col>
      :
      <v-col>
        <v-select
          v-model="select_Secound"
          :items="Secound"
          item-text="Secound"
          item-value="Secound"
          label="Secound"
          persistent-hint
          return-object
          single-line
          @change="datasend"
        ></v-select>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["widget"],
  data() {
    return {
      checkbox: true,
      select_Hour: 0,
      select_Minute: 0,
      select_Secound: 0,
      Hour: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23,
      ],
      Minute: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37,
        38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55,
        56, 57, 58, 59,
      ],
      Secound: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37,
        38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55,
        56, 57, 58, 59,
      ],
    };
  },
  methods: {
    ...mapActions(["doPublish"]),
    datasend() {
      this.doPublish({
        topic: this.widget.topic,
        payload: String(
          Number(this.select_Hour * 3600) +
            Number(this.select_Minute * 60) +
            Number(this.select_Secound)
        ),
      });

      this.widget.backup.H = this.select_Hour;
      this.widget.backup.M = this.select_Minute;
      this.widget.backup.S = this.select_Secound;
    },
    update() {
      try {
        this.select_Hour = this.widget.backup.H;
        this.select_Minute = this.widget.backup.M;
        this.select_Secound = this.widget.backup.S;
      } catch (err) {
        //
      }
    },
  },
  mounted() {
    this.update();
  },
};
</script>

<style></style>
