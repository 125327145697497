<template>
  <div v-show="widget.show && widget.pageId == pageId">
    <VueDragResize
      :w="widget.size?.w || 100"
      :h="widget.size?.h || 100"
      :x="widget.size?.x || 0"
      :y="widget.size?.y || 0"
      :parentH="windowHeight - 100"
      :parentW="windowWidth"
      :parentLimitation="true"
      :minw="widget.size?.minw || 50"
      :minh="widget.size?.minh || 50"
      :sticks="['br']"
      :snapToGrid="true"
      :gridX="10"
      :gridY="20"
      v-on:activated="activate"
      v-on:deactivated="deactivate"
      v-on:resizestop="deactivate"
      v-on:dragstop="deactivate"
      v-on:resizing="resize"
      v-on:dragging="resize"
      :isActive="this.$store.state.edit"
      :preventActiveBehavior="!this.$store.state.edit"
    >
      <v-card
        :height="widget.size.h"
        :width="widget.size.w"
        style="padding: 5px"
      >
        <v-icon
          style="position: absolute; right: 2px; font-size: 20px; padding: 10px"
          @click="Setting()"
          >mdi-cog</v-icon
        >
        <v-icon
          v-show="this.$store.state.edit"
          style="
            position: absolute;
            right: 2px;
            bottom: 2px;
            font-size: 14px;
            color: indianred;
          "
          @click="deleteWidget"
          >mdi-delete</v-icon
        >

        <v-list-item-content two-lines>
          <v-list-item-title
            style="
              margin-top: -8px;
              margin-left: 10px;
              font-size: 18px;
              font-weight: bold;
            "
            class="text-body"
          >
            {{ widget.name }}
          </v-list-item-title>
          <v-list-item-subtitle
            style="margin-top: -2px; margin-left: 10px; font-size: 10px"
          >
            {{ new Date(widget.timestamp).toLocaleDateString("th-TH") }}
            {{ new Date(widget.timestamp).toLocaleTimeString("th-TH") }}
          </v-list-item-subtitle>
        </v-list-item-content>

        <component
          :is="widget.type"
          :widget="widget"
          style="overflow: hidden"
        />
      </v-card>
    </VueDragResize>
    <v-dialog v-model="setting" max-width="900px">
      <v-card
        elevation="16"
        class="mx-auto pa-4"
        :style="{ height: '80vh', overflowY: 'auto' }"
      >
        <v-virtual-scroll :bench="100" :items="items" item-height="64">
          <v-list
            class="font-weight-bold text-center"
            style="background-color: #d1c4e9; font-size: 20px"
          >
            {{ widget.type }}
          </v-list>
          <v-divider></v-divider>

          <!-- Name and Topic Inputs -->
          <v-container>
            <v-row>
              <v-col cols="10" sm="6">
                <v-text-field
                  v-if="Object.keys(widget).includes('name')"
                  v-model="widget.name"
                  label="Name"
                  outlined
                  dense
                  class="pa-2 custom-label"
                ></v-text-field>
              </v-col>

              <v-col cols="10" sm="6">
                <v-select
                  v-if="
                    Object.keys(widget).includes('topic') &&
                    widget.deviceType !== 'control'
                  "
                  :items="devices"
                  item-text="topic"
                  label="Topic"
                  v-model="widget.topic"
                  outlined
                  dense
                  class="pa-2 custom-label"
                ></v-select>
              </v-col>
            </v-row>

            <!-- Parameters Section -->
            <v-row v-if="widget.params">
              <v-col
                v-for="(p, i) in Object.keys(widget.params)"
                :key="i"
                cols="8"
                sm="3"
              >
                <v-text-field
                  v-if="p !== 'Color' && p !== 'Load'"
                  v-model="widget.params[p]"
                  :label="p"
                  outlined
                  dense
                  class="custom-label"
                ></v-text-field>
                <v-select
                  v-if="hasParams() && p === 'Load'"
                  :items="load_type"
                  item-text="Load"
                  label="Load Type"
                  v-model="widget.params[p]"
                  outlined
                  dense
                  @click="ch"
                ></v-select>
              </v-col>
            </v-row>

            <v-row v-if="hasParams() && widget.params.Color">
              <v-col cols="12">
                <div class="d-flex align-center pa-2">
                  <span>Color: </span>
                  <v-btn
                    :color="widget.params.Color"
                    @click="settingcolor = !settingcolor"
                    class="ml-3"
                    small
                  >
                    {{ currentColorName }}
                  </v-btn>
                </div>

                <!-- Color Selection Buttons -->
                <div class="d-flex align-center pa-2">
                  <v-btn
                    v-for="(color, index) in colorOptions"
                    :key="index"
                    :color="color"
                    class="ma-2"
                    small
                    @click="setColor(color)"
                  ></v-btn>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-virtual-scroll>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VueDragResize from "vue-drag-resize";
import { mapState } from "vuex";
import WeatherStation from "../widgets/WeatherStation.vue";
import Lamp from "../widgets/Lamp.vue";
import ToggleSwitch from "../widgets/ToggleSwitch.vue";
import Gauge from "../widgets/ApexGauge.vue";
import Chart from "../widgets/AreaChart1.vue";
// import Chart_2 from "../widgets/AreaChart2.vue";
import TextInput from "../widgets/TextInput.vue";
import SilderHorizontal from "../widgets/Silder.vue";
import TimeInput from "../widgets/TimeInput.vue";
import Step from "../widgets/StepInput.vue";
import ValueDisplay from "../widgets/ValueDisplay.vue";

export default {
  computed: {
    ...mapState(["pageId", "devices", "widgets"]),
    items() {
      return Array.from({ length: 1 }, (k, v) => v + 1);
    },
    currentColorName() {
      // Reverse lookup from Hex to color name
      const colorName = Object.keys(this.colorHexMap).find(
        (key) => this.colorHexMap[key] === this.widget.params.Color
      );
      return colorName || this.widget.params.Color; // Fallback to Hex if no match found
    },
  },
  components: {
    VueDragResize,
    WeatherStation,
    Lamp,
    ToggleSwitch,
    Gauge,
    Chart,
    // Chart_2,
    TextInput,
    SilderHorizontal,
    TimeInput,
    Step,
    ValueDisplay,
  },
  props: ["widget"],
  data() {
    return {
      setting: false,
      load_type: [
        "Lamp",
        "Fan",
        "Pump",
        "Air Conditioner",
        "Heater",
        "Door Lock",
        "Motion Sensor",
      ],
      Arrow: "mdi-arrow-left-bold",
      iconShow: false,
      zindex: -1,
      havecolor: false,
      settingcolor: false,
      colorOptions: [
        "red",
        "blue",
        "green",
        "yellow",
        "orange",
        "purple",
        "black",
        "white",
      ],
      colorHexMap: {
        red: "#FF0000",
        blue: "#0000FF",
        green: "#008000",
        yellow: "#FFFF00",
        orange: "#FFA500",
        purple: "#800080",
        black: "#000000",
        white: "#FFFFFF",
      },
    };
  },

  methods: {
    activate() {
      this.zindex = 999;
    },
    deactivate() {
      this.zindex = -1;
    },
    resize(rect) {
      this.widget.size.w = rect.width - (rect.width % 20);
      this.widget.size.h = rect.height - (rect.height % 20);
      this.widget.size.x = rect.left;
      this.widget.size.y = rect.top;
    },
    deleteWidget() {
      this.widgets
        .filter((f) => f.id == this.widget.id)
        .forEach((w) => {
          w.show = false;
        });
    },
    Setting() {
      this.setting = true;
    },
    hasParams() {
      return this.widget.params !== undefined;
    },
    setColor(color) {
      const hexColor = this.colorHexMap[color];
      this.widget.params.Color = hexColor;
    },
    ch() {
      console.log(this.widget.params);
    },
  },
};
</script>

<style>
.custom-label .v-label {
  font-size: 22px;
  font-weight: bold;
}
</style>
