<template>
  <v-alert text prominent :type="msgtype">
    {{ errorMsg }}
  </v-alert>
</template>

<script>
export default {
  props: ["errorMsg", "msgtype"],
};
</script>

<style></style>
