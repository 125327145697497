<template>
  <v-card-text>
    <v-row align="center" justify="space-around">
      <v-col class="text-h5">
        <v-text-field
          v-model="widget.payload"
          :label="'TEXT INPUT'"
          style="padding: 0; margin-top: 20px; font-size: 26px"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row align="center" justify="space-around">
      <v-btn class="ma-1" color="green" @click="datasend"> OK</v-btn>
      <v-btn class="ma-1" color="red-lighten" @click="widget.payload = ''">
        Clear</v-btn
      >
    </v-row>
  </v-card-text>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["widget"],
  methods: {
    ...mapActions(["doPublish"]),
    datasend() {
      this.doPublish({
        topic: this.widget.topic,
        payload: this.widget.payload,
      });
    },
  },
};
</script>

<style></style>
