var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{style:(_vm.style)},[_c('v-app-bar',{attrs:{"app":"","color":"#803D9A","dark":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.checklogin.apply(null, arguments)}}}),_c('div',{staticClass:"d-flex align-center",staticStyle:{"font-size":"1920"}},[_c('v-img',{staticClass:"shrink mr-2",staticStyle:{"border-radius":"50%"},attrs:{"alt":"Logo","contain":"","src":require("@/assets/BClogo.jpg"),"width":"50"}}),_c('div',{staticStyle:{"margin-left":"10px","font-size":"2vh"}},[_vm._v("BC Dashboard")]),(this.$store.state.user != null)?_c('div',{staticStyle:{"margin-left":"10px","font-size":"1vw"}},[_vm._v(" [ "+_vm._s(this.$store.state.user.token)+" ] ")]):_vm._e()],1),(
        this.$router.currentRoute.path == '/' &&
        !this.$store.state.edit &&
        this.$store.state.webtype == 'PC'
      )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",staticStyle:{"position":"absolute","right":"80px"},on:{"click":_vm.changeEditMode}},'v-icon',attrs,false),on),[_vm._v("mdi-view-dashboard-edit")])]}}],null,false,2335636810)},[_c('span',[_vm._v("แก้ไข & เคลื่อนย้าย")])]):_vm._e(),(
        this.$router.currentRoute.path == '/' &&
        this.$store.state.webtype == 'PC'
      )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",staticStyle:{"position":"absolute","right":"50px"},on:{"click":function($event){return _vm.saveAll()}}},'v-icon',attrs,false),on),[_vm._v("mdi-floppy")])]}}],null,false,1521004049)},[_c('span',[_vm._v("บันทึก")])]):_vm._e(),(
        this.$router.currentRoute.path == '/' &&
        this.$store.state.webtype == 'PC'
      )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",staticStyle:{"position":"absolute","right":"20px"},on:{"click":function($event){_vm.deletewidget();
            _vm.saveAll();}}},'v-icon',attrs,false),on),[_vm._v("mdi-trash-can")])]}}],null,false,2610672515)},[_c('span',[_vm._v("ลบท้งหมด")])]):_vm._e(),(this.$store.state.user == null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",staticStyle:{"position":"absolute","right":"0px"},on:{"click":_vm.login}},'v-icon',attrs,false),on),[_vm._v("mdi-login")])]}}],null,false,558734782)},[_c('span',[_vm._v("Login")])]):_vm._e(),(this.$store.state.user == null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",staticStyle:{"position":"absolute","right":"30px"},on:{"click":_vm.signup}},'v-icon',attrs,false),on),[_vm._v("mdi-table-edit")])]}}],null,false,2782540276)},[_c('span',[_vm._v("Signup")])]):_vm._e()],1),_c('v-navigation-drawer',{attrs:{"absolute":"","left":"","temporary":"","color":"#925CAF"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-icon',{staticStyle:{"margin-top":"0px","margin-left":"10px","color":"#ffffff"},on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}},[_vm._v("mdi-keyboard-backspace")]),_c('v-divider'),_c('v-list-item-group',{attrs:{"active-class":"deep-purple--text text--accent-4"},model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}},[_c('v-list-item',[_c('v-btn',{staticClass:"deep-purple accent-4",staticStyle:{"margin-top":"10px"},attrs:{"dark":"","permanent":"","width":"100%"},on:{"click":function($event){_vm.globaldashboard();
              _vm.drawer = false;}}},[_c('v-icon',[_vm._v("mdi-view-dashboard")]),_c('v-list-item-title',[_vm._v("Dashboard")])],1)],1),_c('v-divider'),_c('v-list-item',[_c('v-btn',{staticClass:"deep-purple accent-4",staticStyle:{"margin-top":"10px"},attrs:{"dark":"","permanent":"","width":"100%"},on:{"click":function($event){_vm.token();
              _vm.drawer = false;}}},[_c('v-icon',[_vm._v("mdi-list-box")]),_c('v-list-item-title',[_vm._v("token")])],1)],1),_c('v-divider')],1)],1),_c('v-list-item-group',{attrs:{"active-class":"deep-purple--text text--accent-4"},model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}},[_c('v-card',{staticClass:"py-2 white--text text-center",staticStyle:{"color":"white"},attrs:{"elevation":"2","outlined":"","color":"#763599"}},[_vm._v("BC Product")]),_vm._l((this.$store.state.pages),function(device){return _c('v-list-item',{key:device.id},[_c('v-btn',{staticClass:"deep-purple accent-4",staticStyle:{"margin-top":"10px"},attrs:{"dark":"","permanent":"","width":"100%"},on:{"click":function($event){return _vm.gotobcdb(device)}}},[_c('v-icon',[_vm._v("mdi-table-plus")]),_c('v-list-item-title',[_vm._v(_vm._s(device.name))])],1)],1)}),_c('v-list-item',[_c('v-btn',{staticClass:"deep-purple accent-4",staticStyle:{"margin-top":"10px"},attrs:{"dark":"","permanent":"","width":"100%"},on:{"click":function($event){_vm.Add_dashboard = true;
            _vm.drawer = false;}}},[_c('v-icon',[_vm._v("mdi-table-plus")]),_c('v-list-item-title',[_vm._v("Add device")])],1)],1),_c('v-divider')],2),_c('div',{staticClass:"pa-2"},[_c('v-btn',{attrs:{"block":"","color":"red","dark":"","bottom":""},on:{"click":_vm.logout}},[_vm._v(" Logout ")])],1)],1),_c('v-main',{staticClass:"main-content"},[_c('v-dialog',{attrs:{"width":"70%"},model:{value:(_vm.Add_dashboard),callback:function ($$v) {_vm.Add_dashboard=$$v},expression:"Add_dashboard"}},[_c('v-card',{staticClass:"mx-auto",staticStyle:{"overflow":"hidden"},attrs:{"elevation":"16","height":"500px"}},[_c('v-toolbar',{attrs:{"color":"indigo","dark":""}},[_c('v-toolbar-title',[_vm._v("Add device")]),_c('v-spacer')],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.BCdevice),function(card){return _c('v-col',{key:card.name,attrs:{"cols":card.flex,"sm":"4"}},[_c('v-card',[_c('v-img',{staticClass:"white--text align-end",attrs:{"src":card.src,"gradient":"to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)","height":"150px"}},[_c('v-card-title',[_vm._v(_vm._s(card.name))])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","color":"primary"},on:{"click":function($event){return _vm.adddashboard(card)}}},[_vm._v(" ADD ")])],1)],1)],1)}),1)],1)],1)],1),_c('router-view')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }