<template>
  <v-app v-bind:style="style">
    <v-app-bar app color="#803D9A" dark>
      <v-app-bar-nav-icon @click.stop="checklogin"></v-app-bar-nav-icon>

      <div class="d-flex align-center" style="font-size: 1920">
        <v-img
          alt="Logo"
          class="shrink mr-2"
          contain
          src="@/assets/BClogo.jpg"
          style="border-radius: 50%"
          width="50"
        />
        <div style="margin-left: 10px; font-size: 2vh">BC Dashboard</div>
        <div
          style="margin-left: 10px; font-size: 1vw"
          v-if="this.$store.state.user != null"
        >
          [ {{ this.$store.state.user.token }} ]
        </div>
      </div>

      <v-tooltip
        bottom
        v-if="
          this.$router.currentRoute.path == '/' &&
          !this.$store.state.edit &&
          this.$store.state.webtype == 'PC'
        "
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on"
            @click="changeEditMode"
            class="mr-2"
            style="position: absolute; right: 80px"
            >mdi-view-dashboard-edit</v-icon
          >
        </template>
        <span>แก้ไข & เคลื่อนย้าย</span>
      </v-tooltip>

      <v-tooltip
        bottom
        v-if="
          this.$router.currentRoute.path == '/' &&
          this.$store.state.webtype == 'PC'
        "
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on"
            @click="saveAll()"
            class="mr-2"
            style="position: absolute; right: 50px"
            >mdi-floppy</v-icon
          >
        </template>
        <span>บันทึก</span>
      </v-tooltip>

      <v-tooltip
        bottom
        v-if="
          this.$router.currentRoute.path == '/' &&
          this.$store.state.webtype == 'PC'
        "
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on"
            @click="
              deletewidget();
              saveAll();
            "
            class="mr-2"
            style="position: absolute; right: 20px"
            >mdi-trash-can</v-icon
          >
        </template>
        <span>ลบท้งหมด</span>
      </v-tooltip>

      <v-tooltip bottom v-if="this.$store.state.user == null">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on"
            @click="login"
            class="mr-2"
            style="position: absolute; right: 0px"
            >mdi-login</v-icon
          >
        </template>
        <span>Login</span>
      </v-tooltip>

      <v-tooltip bottom v-if="this.$store.state.user == null">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on"
            @click="signup"
            class="mr-2"
            style="position: absolute; right: 30px"
            >mdi-table-edit</v-icon
          >
        </template>
        <span>Signup</span>
      </v-tooltip>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      absolute
      left
      temporary
      color="#925CAF"
    >
      <v-list nav dense>
        <v-icon
          style="margin-top: 0px; margin-left: 10px; color: #ffffff"
          @click.stop="drawer = !drawer"
          >mdi-keyboard-backspace</v-icon
        >
        <v-divider />
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item>
            <v-btn
              class="deep-purple accent-4"
              dark
              permanent
              width="100%"
              @click="
                globaldashboard();
                drawer = false;
              "
              style="margin-top: 10px"
            >
              <v-icon>mdi-view-dashboard</v-icon>
              <v-list-item-title>Dashboard</v-list-item-title>
            </v-btn>
          </v-list-item>

          <v-divider />

          <v-list-item>
            <v-btn
              class="deep-purple accent-4"
              dark
              permanent
              width="100%"
              @click="
                token();
                drawer = false;
              "
              style="margin-top: 10px"
            >
              <v-icon>mdi-list-box</v-icon>
              <v-list-item-title>token</v-list-item-title>
            </v-btn>
          </v-list-item>

          <v-divider />
        </v-list-item-group>
      </v-list>

      <v-list-item-group
        v-model="group"
        active-class="deep-purple--text text--accent-4"
      >
        <v-card
          class="py-2 white--text text-center"
          elevation="2"
          outlined
          color="#763599"
          style="color: white"
          >BC Product</v-card
        >
        <v-list-item v-for="device in this.$store.state.pages" :key="device.id">
          <v-btn
            class="deep-purple accent-4"
            dark
            permanent
            width="100%"
            style="margin-top: 10px"
            @click="gotobcdb(device)"
          >
            <v-icon>mdi-table-plus</v-icon>
            <v-list-item-title>{{ device.name }}</v-list-item-title>
          </v-btn>
        </v-list-item>

        <v-list-item>
          <v-btn
            class="deep-purple accent-4"
            dark
            permanent
            width="100%"
            @click="
              Add_dashboard = true;
              drawer = false;
            "
            style="margin-top: 10px"
          >
            <v-icon>mdi-table-plus</v-icon>
            <v-list-item-title>Add device</v-list-item-title>
          </v-btn>
        </v-list-item>

        <v-divider />
      </v-list-item-group>

      <div class="pa-2">
        <v-btn block @click="logout" color="red" dark bottom> Logout </v-btn>
      </div>
    </v-navigation-drawer>

    <v-main class="main-content">
      <!-- เนื้อหา widget หรือ component ต่างๆ -->
      <v-dialog v-model="Add_dashboard" width="70%">
        <v-card
          style="overflow: hidden"
          elevation="16"
          class="mx-auto"
          height="500px"
        >
          <v-toolbar color="indigo" dark>
            <v-toolbar-title>Add device</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>

          <v-container fluid>
            <v-row dense>
              <v-col
                v-for="card in BCdevice"
                :key="card.name"
                :cols="card.flex"
                sm="4"
              >
                <v-card>
                  <v-img
                    :src="card.src"
                    class="white--text align-end"
                    gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                    height="150px"
                  >
                    <v-card-title>{{ card.name }}</v-card-title>
                  </v-img>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      depressed
                      color="primary"
                      @click="adddashboard(card)"
                    >
                      ADD
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>

      <router-view />
    </v-main>

    <!-- <v-footer dark padless class="footer">
      <v-card class="flex" flat tile>
        <v-card-text class="py-2 white--text text-center">
          Copyright © {{ new Date().getFullYear() }} —
          <strong>BC Programming</strong>
        </v-card-text>
      </v-card>
    </v-footer>-->
  </v-app>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "App",

  data: () => ({
    tab: null,
    remove: false,
    rename: false,
    drawer: false,
    group: null,
    Add_dashboard: false,
    BCdevice: [],
  }),
  watch: {
    group() {
      this.drawer = false;
    },
  },
  computed: {
    ...mapState(["widgets", "pages", "pageId", "style"]),
  },
  methods: {
    ...mapActions(["doConnection", "doCheckRuleEngine", "saveAll", "loadAll"]),
    addPage() {
      const newId =
        this.pages.length == 0 ? 1 : this.pages[this.pages.length - 1].id + 1;
      this.pages.push({
        id: newId,
        name: "page" + Number(newId),
        show: true,
      });
      this.$store.state.pageId = newId;
      this.findTab();
    },
    findTab() {
      for (var i = 0; i < this.pages.lenght; i++) {
        if (this.pages[i].id == this.pageId) {
          this.tab = i;
        }
      }
    },
    removePage() {
      this.remove = true;
    },
    confirmRemovePage() {
      this.widgets
        .filter((f) => f.pageId == this.pageId)
        .map((m) => (m.show = false));
      this.pages.filter((f) => f.id == this.pageId)[0].show = false;
      this.$store.state.pageId =
        this.pageId.lenght == 0
          ? 0
          : this.pages.filter((f) => f.show == true)[0].id;
      this.findTab();
      this.remove = false;
    },
    renamePage() {
      this.rename = true;
    },
    tabChange() {
      this.$store.state.pageId = this.pages[this.tab].id;
    },
    login() {
      this.$router.push("/login");
    },
    logout() {
      this.drawer = false;
      console.log("unsubscribe");
      this.$store.state.client.unsubscribe(this.$store.state.user.token);
      localStorage.setItem("user", "");
      this.$store.state.user = null;
      this.$store.state.client.end();
      this.$store.state.currentPath = "login";
      this.$router.push("/login");
    },
    signup() {
      this.$store.state.currentPath = "signup";
      this.$router.push("/signup");
    },
    globaldashboard() {
      this.$router.push("/");
    },
    token() {
      this.$store.state.currentPath = "token";
      this.$router.push("/token");
    },
    account() {
      this.$store.state.currentPath = "account";
      this.$router.push("/account");
    },
    adddashboard(card) {
      console.log(card);
      this.Add_dashboard = false;

      const newObject = {
        id: this.$store.state.pages.length,
        name: card.name,
        nameshow: card.name,
        token: " ",
        BCDBWidgets: [],
      };
      this.$store.state.pages.push(newObject);
    },
    gotobcdb(BC_page) {
      console.log(BC_page);

      if (BC_page.name == "Smart EC&PH")
        this.$store.state.currentPath = "/bcdb/smartecph/" + BC_page.token;
      this.$router.push("/bcdb/smartecph/" + BC_page.token);
    },
    changeDashboardMode() {
      if (this.$store.state.style.backgroundColor == "#444444") {
        this.$store.state.style.backgroundColor = "#FFFFFF";
      } else {
        this.$store.state.style.backgroundColor = "#444444";
      }
    },
    changeEditMode() {
      if (this.$store.state.edit == false) {
        this.$store.state.edit = true;
      } else {
        this.$store.state.edit = false;
      }
    },
    deletewidget() {
      var widget = this.$store.state.widgets;
      Object.keys(widget).forEach((v) => {
        this.$store.state.widgets[v].show = false;
        this.$store.state.widgets[v] = {};
      });
    },
    checklogin() {
      if (this.$store.state.user != null) {
        this.drawer = !this.drawer;
      }
    },
  },
  async mounted() {
    if (
      this.$router.currentRoute.path.indexOf("/resetpassword") > -1 &&
      this.$route.params.token != null
    ) {
      this.reset = true;
      this.token = this.$route.params.token;
    }
    await this.loadAll();
    if (this.$store.state.user != null) {
      this.doConnection();
      if (
        this.$store.state.user != null &&
        this.$router.currentRoute.path != "/"
      ) {
        this.userInfo =
          "[ " +
          this.$store.state.user.name +
          " / " +
          this.$store.state.user.token +
          " ]";
        this.$store.state.currentPath = "/";
        this.$router.push("/");
      }
    } else {
      console.log("App.vue", this.$router.currentRoute.path);
      if (!this.reset) {
        this.$store.state.currentPath = "login";
        this.$router.push("/login");
      }
    }
    if (this.tab != this.pageId) this.tab = this.pageId;
    this.$store.watch(
      (state) => {
        return state.currentPath;
      },
      () => {},
      { deep: true }
    );
  },
};
</script>
<style scoped>
.main-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1; /* ทำให้คอนเทนเนอร์ widget ขยายได้ */
  min-height: 100vh; /* ทำให้คอนเทนเนอร์ครอบคลุมความสูงเต็มหน้าจอ */
}
</style>