<template>
  <div>
    <v-card-text>
      <v-row align="center" dense>
        <v-col class="text-h5 font-weight-bold">
          <v-icon>mdi-thermometer-lines</v-icon>{{ widget.payload.temp }} &deg;C
        </v-col>
      </v-row>
      <v-row align="center" dense style="margin-top: -10px">
        <v-col class="text-h5 font-weight-bold">
          <v-icon>mdi-water-percent</v-icon> {{ widget.payload.humi }} %
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
export default {
  props: ["widget"],
};
</script>

<style></style>
