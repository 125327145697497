<template>
  <v-card>
    <v-list-item-content two-lines>
      <v-list-item-title
        style="
          margin-top: -8px;
          margin-left: 10px;
          font-size: 18px;
          font-weight: bold;
        "
        class="text-body"
      >
        {{ widget.name }}
      </v-list-item-title>
      <v-list-item-subtitle
        style="margin-top: -2px; margin-left: 10px; font-size: 10px"
      >
        {{ new Date(widget.timestamp).toLocaleDateString("th-TH") }}
        {{ new Date(widget.timestamp).toLocaleTimeString("th-TH") }}
      </v-list-item-subtitle>
    </v-list-item-content>

    <component :is="widget.type" :widget="widget" style="overflow: hidden" />
  </v-card>
</template>

<script>
import VueDragResize from "vue-drag-resize";
import { mapState } from "vuex";
import WeatherStation from "../widgets/WeatherStation.vue";
import Lamp from "../widgets/Lamp.vue";
import ToggleSwitch from "../widgets/ToggleSwitch.vue";
import Gauge from "../widgets/ApexGauge.vue";
import Chart from "../widgets/AreaChart1.vue";
// import Chart_2 from "../widgets/AreaChart2.vue";
import TextInput from "../widgets/TextInput.vue";
import SilderHorizontal from "../widgets/Silder.vue";
import Timer from "../widgets/TimeInput.vue";
import Step from "../widgets/StepInput.vue";
import ValueDisplay from "../widgets/ValueDisplay.vue";

export default {
  computed: {
    ...mapState(["pageId", "devices", "widgets"]),
    items() {
      return Array.from({ length: 1 }, (k, v) => v + 1);
    },
  },
  components: {
    VueDragResize,
    WeatherStation,
    Lamp,
    ToggleSwitch,
    Gauge,
    Chart,
    // Chart_2,
    TextInput,
    SilderHorizontal,
    Timer,
    Step,
    ValueDisplay,
  },
  props: ["widget"],
  data() {
    return {
      setting: false,
      Arrow: "mdi-arrow-left-bold",
      iconShow: false,
      zindex: -1,
      settingcolor: false,
    };
  },
  methods: {
    activate() {
      this.zindex = 999;
    },
    deactivate() {
      this.zindex = -1;
    },
    resize(rect) {
      this.widget.size.w = rect.width - (rect.width % 20);
      this.widget.size.h = rect.height - (rect.height % 20);
      this.widget.size.x = rect.left;
      this.widget.size.y = rect.top;
    },
    deleteWidget() {
      this.widgets
        .filter((f) => f.id == this.widget.id)
        .forEach((w) => {
          w.show = false;
        });
      this.$store.state.widgets[this.widget.id] = {};
    },
    Setting() {
      this.setting = true;
    },
    test(a) {
      console.log(a, this.iconShow, this.widget.lock);
    },
  },
};
</script>

<style></style>
