<template>
  <div>
    <!-- App Bar -->
    <v-app-bar color="#6F3A9F" dark elevate-on-scroll>
      <v-toolbar-title>Token Manager</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        class="app-bar-btn"
        color="green"
        @click="resetAddDialog"
        >ADD</v-btn
      >
    </v-app-bar>

    <!-- Table Section -->
    <div class="table-container">
      <v-card class="table-card">
        <v-simple-table>
          <thead>
            <tr>
              <th class="text-center"><h3>Token</h3></th>
              <th class="text-center"><h3>Payload</h3></th>
              <th class="text-center"><h3>Actions</h3></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in channellist" :key="item.i" class="table-row">
              <td>{{ item.channel }}</td>
              <td>
                <div v-if="findDevice(item.channel)">{{ findDevice(item.channel).payload }}</div>
              </td>
              <td class="action-btns">
                <v-btn color="orange" class="action-btn" @click="openEditDialog(item)">Edit</v-btn>
                <v-btn :color="item.EnableLog ? 'red' : 'green'" class="action-btn" @click="edit_log(item)">
                  {{ item.EnableLog ? "Disable Log" : "Enable Log" }}
                </v-btn>
                <v-btn color="red" class="action-btn" @click="remove(item)">Delete</v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>
    </div>

    <!-- Add Channel Dialog -->
    <v-dialog v-model="add" max-width="600px">
      <v-card>
        <v-card-title class="dialog-title">Add Channel</v-card-title>
        <br>
        <v-card-text>
          <v-text-field
            v-model="addtoken"
            label="Topic"
            outlined
            dense
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" @click="addchanel">Confirm</v-btn>
          <v-btn text @click="add = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Edit Payload Dialog -->
    <v-dialog v-model="edit" max-width="600px">
      <v-card>
        <v-card-title class="dialog-title">Edit Payload</v-card-title>
        <br>
        <v-card-text>
          <v-text-field
            v-model="editpayload"
            label="Payload"
            outlined
            dense
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" @click="edit_payload">Send</v-btn>
          <v-btn text @click="edit = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      channellist: [],
      add: false,
      addtoken: "",
      edit: false,
      editpayload: "",
      edittoken: "",
    };
  },
  methods: {
    ...mapActions(["doPublish", "doConnection"]),
    async channelupdate() {
      const response = await axios.get(
        `${this.$store.state.serverUrl}channel/${this.$store.state.user.token}`
      );
      this.channellist = response.data;
      this.doConnection();
    },
    async remove(item) {
      await axios.get(`${this.$store.state.serverUrl}channelremove/${item.channel}`);
      this.channelupdate();
    },
    async addchanel() {
      await axios.get(
        `${this.$store.state.serverUrl}channeladd/${this.$store.state.user.token}/${this.addtoken}`
      );
      this.channelupdate();
      this.add = false;
    },
    async edit_log(item) {
      await axios.get(`${this.$store.state.serverUrl}editlog/${item.channel}`);
      this.channelupdate();
    },
    edit_payload() {
      this.doPublish({ topic: this.edittoken, payload: this.editpayload });
      this.edit = false;
    },
    resetAddDialog() {
      this.add = true;
      this.addtoken = "";
    },
    findDevice(channel) {
      return this.$store.state.devices.find((device) => device.topic === channel);
    },
    openEditDialog(item) {
      const device = this.findDevice(item.channel);
      this.editpayload = device ? device.payload : "";
      this.edittoken = item.channel;
      this.edit = true;
    },
  },
  mounted() {
    this.channelupdate();
  },
};
</script>

<style scoped>
.action-btns {
  display: flex;
  justify-content: center; /* Horizontally center the buttons */
  align-items: center; /* Vertically align the buttons */
  gap: 10px; /* Add spacing between buttons */
}
td {
  text-align: center; /* Center the content inside the table cell */
  vertical-align: middle; /* Vertically align content */
}
.table-container {
  display: flex;
  justify-content: center;
  padding: 20px;
}
.table-card {
  width: 95%;
  max-width: 1200px;
  margin: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.table-row:nth-child(even) {
  background-color: #f9f9f9;
}
.action-btns {
  display: flex;
  gap: 10px;
}
.dialog-title {
  background-color: #6F3A9F;
  color: white;
  font-size: 18px;
  font-weight: bold;
  padding: 10px 20px;
}
</style>
