<template>
  <v-app v-bind:style="this.style">
    <v-container fill-height >
      <v-row justify="center" align="center">
        <v-card width="600" style="padding: 10px" >
          <error-handler
            :errorMsg="errMsg"
            :msgtype="msgtype"
            v-if="errMsg != ''"
          />
          <v-toolbar color="#803D9A" dark>
            <v-toolbar-title>Login</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <form @submit.prevent="login()">
              <v-text-field
                v-model="email"
                placeholder="email"
                name="email"
                label="Email"
                type="email"
                required
              />
              <v-text-field
                v-model="password"
                placeholder="password"
                name="password"
                label="Password"
                type="password"
                required
              />
              <v-app-bar elevation="0" color="transparent">
                <v-btn class="mr-4" type="submit" style="position: absolute; top: 15px; left: 2px; ">Submit</v-btn>
                <v-btn style="position: absolute; top: 15px; left: 100px;" @click="clear">Clear</v-btn>
                <v-btn style="position: absolute; top: 15px; right: -20px;" text @click="$router.push('/forgotpassword')"
                  >Forgot password</v-btn
                >
              </v-app-bar>
            </form>
          </v-card-text>
        </v-card>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
import ErrorHandler from "../components/ErrorHandler.vue";
import axios from "axios";
//import { response } from 'express';

export default {
  components: {
    ErrorHandler,
  },
  data() {
    return {
      email: "",
      password: "",
      errMsg: "",
      msgtype: "",
      style: {'background-image':'url(http://dashboard.bcallcenter.com/img/1.png)'},
    };
  },
  methods: {
    ...mapActions(["doConnection", "loadAll"]),
    clear() {
      this.email = "";
      this.password = "";
      this.errMsg = "";
    },
    async login() 
    {
      this.errMsg = "";
      await axios
        .get(
          this.$store.state.serverUrl +
            "getuser/" +
            this.email +
            "/" +
            this.password
        )
        .then((response) => {
          return response;
        })
        .then((data) => {
          if (data.data.err) {
            this.msgtype = "error";
            this.errMsg = data.data.err;
          } else {
            this.$store.state.user = data.data[0];
            console.log(data.data[0].status);
            localStorage.setItem(
              "user",
              JSON.stringify(this.$store.state.user)
            );

            this.msgtype = "success";
            this.errMsg = "Login Success";
            this.loadAll();
            this.doConnection();
            this.$store.state.currentPath = "/";
            this.$router.push("/");
          }
        });
    },
  },
};
</script>

<style></style>
