<template>
  <v-card-text class="d-flex justify-center" style="height: 75%">
    <v-icon
      v-if="widget.payload == '1'"
      :color="Color"
      style="font-size: 100px"
    >
      {{ getIcon }}
    </v-icon>
    <v-icon v-else style="font-size: 100px">
      {{ getOutlineIcon }}
    </v-icon>
  </v-card-text>
</template>

<script>
export default {
  props: ["widget"],
  data() {
    return {
      Color: "green",
      iconMap: {
        Lamp: {
          on: "mdi-lightbulb-on",
          off: "mdi-lightbulb-outline",
        },
        Fan: {
          on: "mdi-fan",
          off: "mdi-fan-off",
        },
        Pump: {
          on: "mdi-water-pump",
          off: "mdi-water-pump-off",
        },
        "Air Conditioner": {
          on: "mdi-air-conditioner",
          off: "mdi-air-conditioner",
        },
        Heater: {
          on: "mdi-radiator",
          off: "mdi-radiator-off",
        },
        "Door Lock": {
          on: "mdi-lock",
          off: "mdi-lock-open-outline",
        },
        "Motion Sensor": {
          on: "mdi-motion-sensor",
          off: "mdi-motion-sensor-off",
        },
        "Water Sprinkler": {
          on: "mdi-sprinkler-variant",
          off: "mdi-sprinkler-variant-off",
        },
      },
    };
  },
  computed: {
    getIcon() {
      return this.iconMap[this.widget.params.Load]?.on || "mdi-help";
    },
    getOutlineIcon() {
      return this.iconMap[this.widget.params.Load]?.off || "mdi-help-circle-outline";
    },
  },
  methods: {
    update() {
      try {
        this.Color = this.widget.params.Color;
      } catch (err) {
        console.log("Lamp", "error", err);
      }
    },
  },
  mounted() {
    if (this.widget.params == null) {
      this.widget.params = {
        Color: this.Color,
        Load: "Lamp", // Default Load type
      };
    } else {
      this.Color = this.widget.params.Color;
    }
    this.update();
    this.$store.watch(
      (state) => {
        return state.widgets.filter((d) => d.topic == this.widget.topic);
      },
      () => {
        this.update();
      },
      { deep: true }
    );
  },
};
</script>

<style></style>
