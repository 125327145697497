<template>
  <v-container fill-height>
    <v-row justify="center" align="center">
      <v-card width="600" style="padding: 10px">
        <error-handler
          :errorMsg="errMsg"
          :msgtype="msgtype"
          v-if="errMsg != ''"
        />
        <v-toolbar color="#803D9A" dark>
          <v-toolbar-title>Forgot Password</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <form @submit.prevent="signup()">
            <v-text-field
              v-model="email"
              placeholder="email"
              name="email"
              label="Email"
              type="email"
              required
            >
            </v-text-field>
            <v-app-bar elevation="0" color="transparent">
              <v-btn class="mr-4" type="submit">Submit</v-btn>
              <v-btn @click="clear">Clear</v-btn>
              <v-spacer></v-spacer>
              <v-btn text @click="$router.push('/login')">Login</v-btn>
            </v-app-bar>
          </form>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import ErrorHandler from "../components/ErrorHandler.vue";
export default {
  components: { ErrorHandler },
  data() {
    return {
      email: "",
      password: "",
      errMsg: "",
      msgtype: "",
    };
  },
  methods: {
    clear() {
      this.email = "";
      this.password = "";
      this.errMsg = "";
    },
    async signup() {
      this.errMsg = "";
      await axios
        .get(this.$store.state.serverUrl + "forgotpassword/" + this.email)
        .then((response) => {
          return response;
        })
        .then((data) => {
          console.log(data);

          if (data.data.err) {
            this.msgtype = "error";
            this.errMsg = data.data.err;
          }
          if (data.data.msg) {
            this.msgtype = "success";
            this.errMsg = data.data.msg;
          }
        });
    },
  },
};
</script>

<style></style>
