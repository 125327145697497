<template>
  <VueApexCharts
    id="chart"
    ref="chart"
    style="overflow-y: hidden"
    type="radialBar"
    :options="chartOptions"
    :series="series"
    @click.native="update"
  >{{ this.chartOptions.fill.colors }}</VueApexCharts>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["widgetparam"]),
  },
  props: ["widget", "chart"],
  components: {
    VueApexCharts,
  },
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          height: 200,
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            startAngle: Number(this.widget.params.startAngle),
            endAngle: Number(this.widget.params.endAngle),
            hollow: {
              margin: 0,
              size: this.widget.params.hollow,
              background: "#fff",
              position: "front",
            },
            track: {
              background: "#fff",
              strokeWidth: "67",
              margin: 0, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: -3,
                left: 0,
                blur: 4,
                opacity: 0.35,
              },
            },

            dataLabels: {
              show: true,
              name: {
                offsetY: "10px",
                show: true,
                color: "#111",
                fontSize: "32px",
              },
              value: {
                show: false,
              },
            },
          },
        },
        fill: {
          colors: ["#00FF4D"],
          gradient: {
            shade: "dark",
          },
        },
        labels: [this.widget.payload + this.widget.params.unit],
      },
    };
  },
  methods: {
    update() {
      try {
        const payload = Number(this.widget.payload);
        const in_min = Number(this.widget.params.min);
        const in_max = Number(this.widget.params.max);
        const out_min = 0;
        const out_max = 100;
        const output =
          ((payload - in_min) * (out_max - out_min)) /
          (in_max - in_min + out_min);

        this.series = [output];

        this.chartOptions.chart.height = this.widget.size.h - 30;
        this.chartOptions.plotOptions.radialBar.hollow.size = Number(
          this.widget.params.hollow
        );

        this.chartOptions.plotOptions.radialBar.startAngle = Number(
          this.widget.params.startAngle
        );
        this.chartOptions.plotOptions.radialBar.endAngle = Number(
          this.widget.params.endAngle
        );
        this.chartOptions.labels = [payload + this.widget.params.unit];

        this.chartOptions.fill.colors[0] = this.widget.params.Color;

        this.$refs.chart.updateOptions(this.chartOptions);
      } catch (err) {
        //
      }
    },
  },
  mounted() {
    this.update();
    this.$store.watch(
      (state) => {
        state.widgets.filter((d) => d.topic == this.widget.topic);
        return state.widgets;
      },
      () => {
        this.update();
      },
      { deep: true }
    );
  },
};
</script>

<style></style>
