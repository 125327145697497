import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Login from "../views/Login.vue";
import Signup from "../views/Signup.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import ResetPassword from "../views/ResetPassword.vue";
import Token from "../views/Token.vue";
import Account from "../views/Account.vue";

import SmartECPH from "../BC_Dashboard/Smart_ECPH.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },
  {
    path: "/forgotpassword",
    name: "forgotpassword",
    component: ForgotPassword,
  },
  {
    path: "/resetpassword/:token",
    name: "resetpassword",
    component: ResetPassword,
  },
  {
    path: "/token",
    name: "Token",
    component: Token,
  },
  {
    path: "/account",
    name: "Account",
    component: Account,
  },

  {
    path: "/bcdb/smartecph/:token",
    name: "SmartECPH",
    component: SmartECPH,
  },

  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
